import Vue from 'vue'
import VueRouter from 'vue-router'
// Global
import LoginView from '../views/LoginView'
import NotFoundView from '../views/NotFoundView'
// Dashboard
import TablaHistorico from '../components/TablaHistorico'
import TablaMovimientoHistorico from '../components/TablaMovimientoHistorico'
import GenerarReporte from '../components/GenerarReporte'
import CargarCatalogo from '../components/CargarCatalogo'
import DescargarPlantilla from '../components/DescargarPlantilla'
import ReporteSL from '../components/ReporteSL'

Vue.use(VueRouter)

const routes = [
  // Dashboard Routes
  {
    path: '/',
    name: 'dashboard',
    component: TablaHistorico,
    meta: {
      requiresAuth: true,
      viewTitle: 'Histórico',
      viewSubtitle: 'Bitácora de movimientos'
    },
  },
  {
    path: '/historico/movimiento/:id',
    name: 'movimientoHistorico',
    component: TablaMovimientoHistorico,
    meta: (route)=>({
      requiresAuth: true,
      viewTitle: `Detalles de movimiento`,
      viewSubtitle: `Items cargados | ID: ${route.params.id.substr(0,6)}`
    }),
  },
  {
    path: '/generar-reporte',
    name: 'generarReporte',
    component: GenerarReporte,
    meta: {
      requiresAuth: true,
      viewTitle: 'Generar reporte',
      viewSubtitle: 'Reporte de movimientos'
    },
  },
  {
    path: '/cargar-catalogo',
    name: 'cargarCatalogo',
    component: CargarCatalogo,
    meta: {
      requiresAuth: true,
      viewTitle: 'Cargar catálogo',
      viewSubtitle: 'Carga todos los artículos que desees mediante la plantilla'
    },
  },
  {
    path: '/descargar-plantilla',
    name: 'descargarPlantilla',
    component: DescargarPlantilla,
    meta: {
      requiresAuth: true,
      viewTitle: 'Descargar plantilla',
      viewSubtitle: 'Archivo para cargar catálogo'
    },
  },
  {
    path: '/reporte-sl',
    name: 'reporteSL',
    component: ReporteSL,
    meta: {
      requiresAuth: true,
      viewTitle: 'Reporte SL',
      viewSubtitle: 'Archivo de reporte shipping list'
    },
  },
  // Global routes
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFoundView,
    name: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Middleware
const asyncBeforeEachHandler = async (to, from, next) => {

  // Wait until KC is ready
  while (router.app.$keycloak.createLoginUrl === null) {
    await new Promise(resolve => setTimeout(resolve, 100))
  }

  let authenticated = router.app.$keycloak.authenticated
  let resourceAccess = router.app.$keycloak.resourceAccess

  let hasAdminAccess = resourceAccess && 
    resourceAccess['admin-catalogos-joy'] && 
    resourceAccess['admin-catalogos-joy'].roles.indexOf('admin') !== -1

  if (to.matched.some(route => route.meta.requiresAuth)) {
    if ( authenticated && hasAdminAccess )
      next()
    else
      next({name: 'login'}) // Force redirect to login
  }else{
    if( to.name == 'login' && ( authenticated && hasAdminAccess) )
      next({name: 'dashboard'}) // Force redirect to dashboard
    else
      next()
  }
}

router.beforeEach(asyncBeforeEachHandler)

export {
  router,
  routes,
  asyncBeforeEachHandler,
}
